import React from "react";
import PropTypes from "prop-types";
import Sticky from "react-sticky-el";

import { Helmet } from "react-helmet";

import Theme, { theme } from "../../packages/themes/src/Startup";
import {
  Navbar,
  Copyright,
  Welcome,
  Services,
  About,
  AboutWeb,
  AboutIot,
  Technology,
  Blog,
  Contact,
  Legal,
} from "../../packages/themes/src/Startup/containers";

import preview from "../../packages/themes/src/Startup/assets/preview.jpg";

import SEO from "../components/SEO";

const Startup = ({ url }) => (
  <Theme>
    <Helmet>
      <meta name="theme-color" content={theme.colors.primary} />
      <meta property="og:image" content={`${url}${preview}`} />
    </Helmet>
    <SEO title="MAAI digital" />

    <Sticky style={{ zIndex: 999, position: "relative" }}>
      <Navbar />
    </Sticky>

    <Welcome name="" />
    <Services name="services" />
    <div name="projects">
      <About name="projects-app" />
      <AboutWeb name="projects-web" />
      <AboutIot name="projects-iot" />
    </div>
    <Technology name="technology" />
    {/* <Blog name="blog" /> */}
    <Contact name="contact" />
    <Copyright />
  </Theme>
);

Startup.propTypes = {
  url: PropTypes.string,
};

Startup.defaultProps = {
  url: "https://www.maai.digital",
};

export default Startup;
